import React, { useEffect, useState } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { getFormattedDateTime } from "../../environment";
import { G_MAPS_API_KEY } from '../../environment';
function GoogleMap(props) {
  let { markers, lat, lng, type } = props
  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const markerData = markers ? (type !== 1 ? markers.scanData : markers) : []
  // const pinInfo = (marker) => {
  //     console.log("marker",marker.browser)
  //     setSelectedElement(marker);
  //     setActiveMarker(marker);

  // }

  return (
    <Map
      style={{ borderRadius: '20px 20px 0px 0px' }}
      containerStyle={{ position: 'relative', zIndex: 1, height: 400 }}
      google={props.google}
      zoom={2}
      initialCenter={
        {
          lat: lat ? lat : 33.333,
          lng: lng ? lng : 73.33
        }
      }
    >
      {markerData && markerData !== undefined &&
        (type !== 1 ? (markers?.scanData || []) : (markers || [])).map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.long }}
            // onClick={(props) => pinInfo(marker)}
            onClick={(props, mark) => {
              setSelectedElement(marker);
              setActiveMarker(mark);
            }}
          >
          </Marker>
        ))
      }
      {selectedElement ? (
        <InfoWindow
          visible={showInfoWindow}
          marker={activeMarker}
          onCloseClick={() => {
            setSelectedElement(null);
          }}
        >
          <div>
            <b>Date Time : </b>{getFormattedDateTime(selectedElement.createdAt)}<br />
            <b>Name : </b>{type !== 1 ? markers.name : selectedElement.record_id.name}
            <br />
            <b>Device Type : </b>{selectedElement.device_type}
            <br />
            <b>OS : </b>{selectedElement.os_type}
            <br />
            <b>Browser </b>: {selectedElement.browser}
            <br />
            <b>City</b> : {selectedElement.city}
            <br />
            <b>Country </b>: {selectedElement.country}
            <br />
            <div className='d-flex'>
              <div className='txt-style'>
                <b>UserAgent </b>
              </div>
              <Tooltip title={selectedElement.user_agent}>
                <div className='icon'>
                  : {selectedElement.user_agent}
                  {/* <InfoOutlinedIcon/> */}
                </div>
              </Tooltip>
            </div>

          </div>
        </InfoWindow>
      ) : null}
    </Map>

  );
}

export default GoogleApiWrapper({
  apiKey: G_MAPS_API_KEY
})(GoogleMap);

