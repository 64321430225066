import React, {useState} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import './search.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'flex-end'
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
}));

function SearchBar(props) {

  const classes = useStyles();


  const {value, placeholder, inputStyle,} = props;
  return (
    <div className={classes.root}>
      <div className="add-search">

        <div className="add-search-wrap">
          <input type="text" placeholder={placeholder} value={value}
                 onChange={(e) => props.onChange(e.target.value)}/>
          <button type="submit">
              <span className="cn_item">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                     // style="fill: #4a4a4a;"
                >
						       <path
                     id="Color_Fill_1" data-name="Color Fill 1"
                     d="M9.844,4.922A4.9,4.9,0,0,1,8.706,8.065L11.861,11.2a0.469,0.469,0,1,1-.661.665L8.041,8.726a4.92,
                      4.92,0,0,1-5.529.489A0.469,0.469,0,0,1,2.972,8.4a3.982,3.982,0,1,0-1.514-1.5,0.469,0.469,0,0,
                     1-.814.464A4.922,4.922,0,1,1,9.844,4.922Z"
                   />
                </svg>
              </span>
          </button>
        </div>
      </div>
      {/*<TextField*/}
      {/*  variant='outlined'*/}
      {/*  margin='dense'*/}
      {/*  placeholder={placeholder}*/}
      {/*  className={clsx(classes.margin, classes.textField)}*/}
      {/*  value={value}*/}
      {/*  InputProps={{*/}
      {/*    startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,*/}
      {/*  }}*/}
      {/*  onChange={(e) => props.onChange(e.target.value)}*/}
      {/*/>*/}
    </div>
  );
}

export default SearchBar;

